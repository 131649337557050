// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use "../node_modules/utimaco-common-ui-angular/assets/global_style_variables.scss" as taas;
@use "../node_modules/utimaco-common-ui-angular/assets/global_styles.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$taas-portal-primary: mat.m2-define-palette(taas.$md-utimacoportal-primary, 500);
$taas-portal-accent: mat.m2-define-palette(taas.$md-utimacoportal-secondary, 500);

// The warn palette is optional (defaults to red).
$utimaco-portal-mock-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$taas-portal-theme: mat.m2-define-light-theme((color: (primary: $taas-portal-primary,
        accent: $taas-portal-accent,
        warn: $utimaco-portal-mock-warn,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($taas-portal-theme);

/* You can add global styles to this file, and also import other style files */


.mat-mdc-card,
.md-card,
.mat-mdc-card-content {
  border-radius: 0px !important;
}
