@use "global_style_variables.scss" as taas;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');


html,
body {
    height: 100%;
    --mdc-protected-button-label-text-weight: 300; //overwriting md button label here, to match style guide
    --mdc-outlined-button-label-text-weight: 300;
    --mdc-text-button-label-text-weight: 300;
}

body {
    margin: 0;
    font-family: taas.$taas-default-font, sans-serif;
    color: taas.$taas-default-text-color;
}

h1 {
    color: taas.$taas-primary-base-color;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
}

h2,
h3 {
    color: taas.$taas-secondary-base-color;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
}

p,
div {
    color: taas.$taas-default-text-color;
    font-size: 13px;
    line-height: 19px;
    font-family: taas.$taas-default-font, sans-serif;
}

button,
.mdc-button {
  border-radius: 3px !important;
}