$taas-primary-base-color:   #0068B4; // Utimaco Blue
$taas-secondary-base-color: #003760;
$taas-sidebar-base-color:   #001c30;
$taas-hightlight-color:     #fbba00; // Utimaco Gold
$taas-default-text-color:   #3C3C3C; // Utimaco Grey Text

$header-height:                64px;
$side-bar-left-width:          50px;
$main-navigation-width:        250px;
$main-navigation-width-mobile: 74px;
$taas-default-font:            "Roboto";

$navigation-z-index: 10000;

$media-medium-screen-width-breakpoint: 600px;
$media-mobile-screen-width-breakpoint: 500px;

//generated using http://mcg.mbitson.com/#!?utimacoportal=%230068b4
$md-utimacoportal-primary: (
    50   : #e0edf6,
    100  : #b3d2e9,
    200  : #80b4da,
    300  : #4d95cb,
    400  : #267fbf,
    500  : #0068b4,
    600  : #0060ad,
    700  : #0055a4,
    800  : #004b9c,
    900  : #003a8c,
    A100 : #b8d0ff,
    A200 : #85aeff,
    A400 : #528bff,
    A700 : #397aff,

    contrast: (
        50   : #000000,
        100  : #000000,
        200  : #000000,
        300  : #000000,
        400  : #ffffff,
        500  : #ffffff,
        600  : #ffffff,
        700  : #ffffff,
        800  : #ffffff,
        900  : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$md-utimacoportal-secondary: (
    50   : #fff7e0,
    100  : #feeab3,
    200  : #fddd80,
    300  : #fccf4d,
    400  : #fcc426,
    500  : #fbba00,
    600  : #fab300,
    700  : #faab00,
    800  : #f9a300,
    900  : #f89400,
    A100 : #ffffff,
    A200 : #fff6eb,
    A400 : #ffdfb8,
    A700 : #ffd39f,

    contrast: (
        50   : #000000,
        100  : #000000,
        200  : #000000,
        300  : #000000,
        400  : #000000,
        500  : #000000,
        600  : #000000,
        700  : #000000,
        800  : #000000,
        900  : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

body {
    margin: 0;
    font-family: $taas-default-font, sans-serif;
    color: $taas-default-text-color;
}